import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Wrap from './components/wrap'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/carbon-sink',
    name: 'CarbonSink',
    component: Wrap,
    redirect: '/carbon-sink/index',
    children: [
      {
        path: 'index',
        meta: { title: '我的积分' },
        component: () => import('./views/CarbonSink/index.vue')
      },
      {
        path: 'what',
        meta: { title: '解释' },
        component: () => import('./views/CarbonSink/what.vue')
      },
      {
        path: 'log',
        meta: { title: '记录详情' },
        component: () => import('./views/CarbonSink/log.vue')
      }
    ]
  },
  {
    path: '/resubmit',
    name: 'ReSubmit',
    component: () => import('./views/Resubmit')
  },
  {
    path: '/area-map',
    meta: { title: '回收点地图' },
    component: () => import('./views/AreaMap')
  }
]

const router = new VueRouter({
  mode: 'history', // 配合微信携带appid必要！！！
  routes
})

export default router
