const helper = {
  /**
   * 格式化日期 pattern Y-M-D h:m:s
   */
  formatTime(value, pattern) {
    function fill(v) {
      return (v < 10 ? '0' : '') + v;
    }
    pattern = pattern || 'Y-M-D h:m:s';
    const date = new Date(parseInt(value));
    const len = pattern.length;
    let ret = pattern;
    for (let i = 0; i < len; i++) {
      switch (pattern.charAt(i)) {
        case 'Y':
            ret = ret.replace(/Y/g, fill(date.getFullYear()));
            break;
        case 'y':
            ret = ret.replace(/y/g, fill(date.getFullYear()).substring(2));
            break;
        case 'M':
            ret = ret.replace(/M/g, fill(date.getMonth() + 1));
            break;
        case 'D':
            ret = ret.replace(/D/g, fill(date.getDate()));
            break;
        case 'h':
            ret = ret.replace(/h/g, fill(date.getHours()));
            break;
        case 'm':
            ret = ret.replace(/m/g, fill(date.getMinutes()));
            break;
        case 's':
            ret = ret.replace(/s/g, fill(date.getSeconds()));
            break;
      }
    }
    return ret;
  },

  /**
   * 隐藏手机号中间四位
   */
  maskPhone(n){
    return n.toString().replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
  },

  /**
   * 是否为微信内置浏览器
   */
  isWeiXin() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
    } else {
      return false;
    }
  },

  /**
   * 是否为支付宝内置浏览器
   */
  isAlipay() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/AlipayClient/i) == 'alipayclient') {
      return true;
    } else {
      return false;
    }
  },

  /**
   * 是否为安卓
   */
  isAndroid() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/Android/i) == 'android' || ua.match(/Linux/i) == 'linux') {
      return true;
    } else {
      return false;
    }
  },

  /**
   * 是否为安卓
   */
  isIos() {
    return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
  },

  /**
   * 是否为IphoneX
   */
  isIphoneX() {
    return /iphone/gi.test(navigator.userAgent) && (screen.height == 812 && screen.width == 375)
  },

  /**
   * 获取URL参数
   */
  queryUrl(key) {
    var query = window.location.search.substring(1)
    var vars = query.split("&")
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=")
      if(pair[0] == key){return pair[1]}
    }
    return(false)
  }

}

export default helper