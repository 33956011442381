import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('openId')
  },
  mutations: {
    updateToken: (state, token) => {
      state.token = token
    }
  },
  actions: {
    updateToken({ commit }, { code, state }) {
      return new Promise((resolve, reject) => {
        axios.get(`/wx/redirect/${state}/greet?code=${code}`).then(response => {
          const res = response.data
          if (res.code === 0 && res.data) {
            sessionStorage.setItem('openId', res.data)
            commit('updateToken', res.data)
            resolve(res.data)
          } else {
            reject()
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
})
